<template>
    <div class="bg-f">
        <!--公共头部-->
        <Header></Header>
        <!-- 用户中心头部 -->
        <user-header />
        <!-- 内容 -->
        <div class="main-content">
            <div class="container">
                <div class="accout-content clearfix" style="display:flex">
                    <!-- 左边 -->
                    <user-left mark="companyvideo"  :userId="userId"/>
                    <!-- 右边 -->
                    <a-card :bordered="false" class="card-right">
                        <div class="first">
                            <span><i style="color:red">*</i>{{ $t('user.video.title') }}:</span>
                            <input type="text" v-model="title">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.video.title-en') }}:</span>
                            <input type="text" v-model="titleEn">
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.video.img') }}:</span>
                            <div style="display:flex">
                                <a-upload
                                    name='file'
                                    list-type="picture-card"
                                    :show-upload-list="false"
                                    :customRequest="uploadImageFile"
                                    :data="{ field: 'indexThumb' }"
                                >
                                    <a-button :loading="indexThumbUploadStatus"> <a-icon type="upload" /> {{ $t('user.video.img-upload') }} </a-button>
                                </a-upload>
                                <img :src="thumb" alt="" v-if="thumb" height="100px">
                            </div>
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.video') }}:</span>
                            <div style="display:flex">
                                <a-upload
                                    name='file'
                                    list-type="picture-card"
                                    :show-upload-list="false"
                                    :customRequest="uploadVideoFile"
                                    :data="{ field: 'videoUrl' }"
                                >
                                    <a-button :loading="videoUrlUploadStatus"> <a-icon type="upload" /> {{ $t('user.video-upload') }} </a-button>
                                </a-upload>
                                <div style="width:300px;height:180px" v-if="url">
                                    <video :src="url" controls="controls" class="video" style="height:100px"></video>
                                </div>
                            </div>
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.isOnShelf') }}:</span>
                            <a-radio-group v-model="isShow" @change="onChange">
                                <a-radio :value="1">
                                    {{ $t('user.put') }}
                                </a-radio>
                                <a-radio :value="0">
                                    {{ $t('user.off') }}
                                </a-radio>
                            </a-radio-group>
                        </div>
                        <div class="second">
                            <span><i style="color:red">*</i>{{ $t('user.sort') }}:</span>
                            <input type="number" v-model="sort">
                        </div>
                        <button class="submit" @click="regsub()">{{ $t('user.submit') }}</button>
                    </a-card>
                </div>
            </div>
        </div>
        <!-- 公共尾部 -->
        <Footer></Footer>
    </div>
</template>

<style>
    .main-content .card-right{
        width: 100%;
    }
    .first,.second{
        text-align: left;
    }
    .first>span,.second>span{
        font-size: 24px;
        width: 220px;
        display: inline-block;
    }
    input[type=text]{
      width: 300px;
      border: 1px solid #787878;
      margin-left: 10px;
    }
    .second{
        margin-top: 20px;
    }
    .submit{
        width: 200px;
        height: 40px;
        margin-top: 40px;
        border: 1px solid  #d9d9d9;
        line-height: 40px;
        background: #ff4d4f;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
    }
    .ant-upload-picture-card-wrapper{
        width: auto;
    }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'VideoAdd',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        title: '',
        titleEn: '',
        thumb: '',
        url: '',
        isShow: 1,
        sort: '',
        companyId: localStorage.companyId,
        duration: '',
        indexThumbUploadStatus: false,
        videoUrlUploadStatus: false,
    }
  },
   methods: {
    onChange(e) {
      this.isShow = e.target.value
    },
    // 上传图片
    uploadImageFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadImageFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          if (field === 'indexThumb') {
            this.thumb = result.url
          }
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
    //上传视频
    uploadVideoFile (file) {
      console.log('file', file)
      const field = file.data.field
      this[field + 'UploadStatus'] = true // 按钮状态
      const formData = new FormData()
      formData.append('file', file.file)
      this.$common.uploadVideoFile(formData).then(data => {
        console.log(data)
        if (data.code === 200) {
          const result = data.data
          this.url = result.url
          this.duration = result.duration
        } else {
          this.$message.error(data.msg)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      }).finally(() => {
        this[field + 'UploadStatus'] = false
      })
    },
    //视频添加
    regsub() {
        // if (this.title == '' || this.titleEn == '' || this.thumb == '' || this.url == '' || this.isShow == '' || this.sort == '') {
        //     this.$layer.alert("请正确填写信息");
        //     return 
        // }
        if (this.title == '' ) {
            this.$layer.alert("视频标题不能为空");
            return 
        } else if (this.titleEn == '' ) {
            this.$layer.alert("英文视频标题不能为空");
            return 
        } else if (this.thumb == '' ) {
            this.$layer.alert("视频封面图不能为空");
            return 
        } else if (this.url == '' ) {
            this.$layer.alert("视频不能为空");
            return 
        } else if (this.isShow == '' ) {
            this.$layer.alert("是否上架不能为空");
            return 
        } else if (this.sort == '' ) {
            this.$layer.alert("排序不能为空");
            return 
        }
        const params = {
            title: this.title,
            titleEn: this.titleEn,
            thumb: this.thumb,
            url: this.url,
            isShow: this.isShow,
            sort: this.sort,
            duration: this.duration
        }
        this.$common.handlePost('/user/company/video/add?companyId='+this.companyId, params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'UsersCompanyVideo', params: {userId: this.userId}})
        } else {
            this.$layer.alert(data.msg);
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>
